import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  CommonParams,
  ErrorMessage,
  GetAllTowerData,
  GetAllTowerParams,
  GetNetworkLogData,
  GetSingleDataParams,
  Params,
  PostRequest,
  TicketFeedbackData,
  TicketGenerateData,
  TicketGenerateOthers,
  TicketNotificationData,
  TowerData,
  UpdateTicketExcelOthers,
} from "../../../../types/redux";

// create a tower
export const createTowerAsync = createAsyncThunk<
  null,
  PostRequest<TowerData, CommonOthers>
>(
  "np-network/createTower",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createTower, data);
      reRender(true);
      toast("success", "Tower create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Tower create fail");
      return rejectWithValue(error);
    }
  }
);

// get all users
export const getAllTowerAsync = createAsyncThunk<
  GetAllTowerData[],
  PostRequest<any, GetAllTowerParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-network/getAllTower", async ({ data, others }, { rejectWithValue }) => {
  try {
    const getData = await AXIOS.post(BASE_API.getAllTower, data, {
      params: others,
    });

    return getData.data?.getData as GetAllTowerData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get all network data fail");
    return rejectWithValue(error);
  }
});

// get all users
export const getAllTowerAsync2 = createAsyncThunk<
  GetAllTowerData[],
  Params<GetAllTowerParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-network/getAllTower2", async ({ params }, { rejectWithValue }) => {
  try {
    const getData = await AXIOS.get(BASE_API.getAllTower2, {
      params,
    });

    return getData.data?.getData as GetAllTowerData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get all network data fail");
    return rejectWithValue(error);
  }
});

// get all count 2
export const getAllCountTowerAsync2 = createAsyncThunk<
  number,
  Params<GetAllTowerParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-network/getCountTower2", async ({ params }, { rejectWithValue }) => {
  try {
    const getData = await AXIOS.get(BASE_API.getCountTower2, {
      params,
    });

    return getData.data?.getData?.[0]?.totalData || 0;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get tower count data fail");
    return rejectWithValue(error);
  }
});

// get count all tower
export const getCountTowerAsync = createAsyncThunk<
  number,
  PostRequest<any, GetAllTowerParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-network/getCountTower", async ({ data, others }, { rejectWithValue }) => {
  try {
    const getData = await AXIOS.post(BASE_API.getCountTower, data, {
      params: others,
    });
    return getData.data?.getData?.[0]?.totalData || 0;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get tower count data fail");
    return rejectWithValue(error);
  }
});

// get single Ticket
export const getSingleTicketAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-network/getSingleTicket", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.getSingleTicket}/${params?.id}`);

    return data.data?.getData as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get tower fail");
    return rejectWithValue(error);
  }
});

// update a tower
export const updateTowerAsync = createAsyncThunk<
  null,
  PostRequest<TowerData, CommonOthers>
>(
  "np-network/updateTower",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.updateTower}/${id}`, data);

      reRender(true);
      toast("success", "Tower update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Tower update fail");
      return rejectWithValue(error);
    }
  }
);

// create a tower
export const createTowerTicketAsync = createAsyncThunk<
  null,
  PostRequest<TicketGenerateData, TicketGenerateOthers>
>(
  "np-network/createTowerTicket",
  async ({ data, others: { reRender, successDone } }, { rejectWithValue }) => {
    try {
      const ticket = await AXIOS.post(BASE_API.createTowerTicket, data);
      reRender(true);
      successDone(ticket?.data?.getData);
      toast("success", "Ticket create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Ticket create fail");
      return rejectWithValue(error);
    }
  }
);

// send feedback
export const sendFeedbackAsync = createAsyncThunk<
  null,
  PostRequest<TicketFeedbackData, CommonOthers>
>(
  "np-network/sendFeedback",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.sendFeedback}/${id}`, data);
      reRender(true);
      toast("success", "Feedback send successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Feedback send fail");
      return rejectWithValue(error);
    }
  }
);

// send network log view
export const networkLogViewAsync = createAsyncThunk<
  GetNetworkLogData,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-network/networkLogView", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.networkLogView}/${params?.id}`);

    return data.data?.getData as GetNetworkLogData;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get log data fail");
    return rejectWithValue(error);
  }
});

// tower ticket notification
export const towerTicketNotificationAsync = createAsyncThunk<
  null,
  PostRequest<TicketNotificationData, null>
>(
  "np-network/towerTicketNotification",
  async ({ data }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.towerTicketNotification, data);
      // reRender(true);
      // toast("success", "Ticket create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Notification send fail");
      return rejectWithValue(error);
    }
  }
);

export const uploadTicketExcelAsync = createAsyncThunk<
  null,
  PostRequest<any[], UpdateTicketExcelOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "np-network/uploadExcel",
  async (
    { data, others: { reRender, uploadedCount } },
    { rejectWithValue }
  ) => {
    const totalItems = data.length;
    let completedItems = 0;
    // let hasError = false;
    let isCreate = false;

    try {
      const uploadPromises = data.map(async (el: any) => {
        try {
          // First API call to create the ticket
          const ticketResponse = await AXIOS.post(
            BASE_API.createTowerTicket,
            el,
            {
              onUploadProgress: () => {
                completedItems++;
                const progressPercentage = Math.round(
                  (completedItems / totalItems) * 100
                );
                uploadedCount(progressPercentage, 100);
              },
            }
          );

          // Extract the ticket ID or necessary data from the response
          const ticketId = ticketResponse?.data?.getData;
          isCreate = true;

          if (!ticketId) {
            console.error("Ticket ID not found in response:", ticketResponse);
            throw new Error("Ticket ID not found in response");
          }

          // Second API call to send the notification
          await AXIOS.post(BASE_API.towerTicketNotification, {
            towerTicketId: ticketId,
          });
        } catch (uploadError) {
          // hasError = true;
          // console.error("Error during upload process:", uploadError);
          const errors = uploadError as AxiosError;
          const error = errors.response?.data as ErrorMessage;
          toast("error", error?.message || "Ticket creation failed");
        } finally {
          // Always increment completedItems and update progress bar
          completedItems++;
          const progressPercentage = Math.round(
            (completedItems / totalItems) * 100
          );
          uploadedCount(progressPercentage, 100);
        }
      });

      // Wait for all promises to settle (either resolved or rejected)
      await Promise.allSettled(uploadPromises);

      // Show error toast if any upload failed
      // if (hasError) {
      //   toast(
      //     "error",
      //     "Some tickets failed to create. Please check the log for more details."
      //   );
      // } else {
      //   toast("success", "All tickets created successfully");
      // }

      if (isCreate) {
        toast("success", "Tickets created successfully");
      }

      // Call reRender function after all uploads are complete
      reRender(true);

      return null;
    } catch (err) {
      // console.error("Final Error:", err);
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Ticket creation failed");
      return rejectWithValue(error);
    }
  }
);

// get tower bulk mail
export const towerBulkMailAsync = createAsyncThunk<
  any,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-network/towerBulkMail", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.towerBulkMail, { params });

    return data.data?.getData as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get tower mail data fail");
    return rejectWithValue(error);
  }
});

// cancel ticket
export const cancelTowerTicketAsync = createAsyncThunk<
  null,
  PostRequest<null, CommonOthers>
>(
  "np-network/cancelTowerTicket",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.towerTicketCancel}/${id}`, data);

      reRender(true);
      toast("success", "Ticket cancel successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Ticket cancel fail");
      return rejectWithValue(error);
    }
  }
);
// cancel ticket
export const towerTicketStartAsync = createAsyncThunk<
  null,
  PostRequest<null, CommonOthers>
>(
  "np-network/towerTicketStart",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.towerTicketStart}/${id}`, data);

      reRender(true);
      toast("success", "PG start successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "PG stop fail");
      return rejectWithValue(error);
    }
  }
);
// stop ticket
export const towerTicketStopAsync = createAsyncThunk<
  null,
  PostRequest<null, CommonOthers>
>(
  "np-network/towerTicketStop",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.towerTicketStop}/${id}`, data);

      reRender(true);
      toast("success", "Ticket stop successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Ticket stop fail");
      return rejectWithValue(error);
    }
  }
);
